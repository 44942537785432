<template>
  <v-container>
    <v-card class="card">
      <v-card-title>
        Transacciones
        <v-btn @click="exportData" class="ml-3">Exportar</v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- <v-btn @click="exportData"  class="ma-2 mx-5" 
        icon
        color="red lighten-2">
        <v-icon>mdi-export-variant</v-icon>
      </v-btn> -->
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="transactions"
        :search="search"
      ></v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { formatCurrency, renewSession } from "@/utils";
export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Nombre de Cliente",
          align: "start",
          value: "payer.name",
        },
        { text: "Email de Cliente", value: "payer.email" },
        { text: "Telefono de Cliente", value: "payer.phone" },
        { text: "Cobrado con Comision", value: "depositedAmount" },
        { text: "Monto Original", value: "amount" },
        { text: "Comision", value: "commission" },
        { text: "Estado", value: "status" },
        { text: "Fecha", value: "date" },
        { text: "No De Pago", value: "order" },
        { text: "No De Aprobacion", value: "approvalCode" },
      ],
      transactions: [],
    };
  },
  async mounted() {
    const resp = await fetch(`${process.env.VUE_APP_API_URL}/transactions`, {
      headers: {
        "x-yoyo-key": localStorage.getItem("session"),
      },
    });

    const { ok, data, errors, errorMessage } = await resp.json();
    if (ok) {
      this.transactions = data.map((x) => ({
        ...x,
        commission: `${x.commission}%`,
        amount : formatCurrency(x.amount),
        depositedAmount : formatCurrency(x.depositedAmount),
      }));
    }
    if (!ok) {
      if (errorMessage) this.error = errorMessage;
      else if (errors) {
        this.error = errors[0].msg;
      } else {
        this.error = resp.statusText;
      }
    }
  },
  methods: {
    async exportData() {
      const resp = await fetch(
        `${process.env.VUE_APP_API_URL}/reports/transactions/me/2021-01-01/2022-12-31/?format=file`,
        {
          headers: {
            "x-yoyo-key": localStorage.getItem("session"),
          },
        }
      );

      const blob = await resp.blob();
      if (blob) {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "yoyo-transactions 2021-01-01 - 2022-12-31.csv";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
        setTimeout(() => URL.revokeObjectURL(link.href), 250);
        renewSession(resp);
      } else {
        if (errorMessage) this.error = errorMessage;
        else if (errors) {
          this.error = errors[0].msg;
        } else {
          this.error = resp.statusText;
        }
      }
    },
  },
};
</script>
